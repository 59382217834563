<template>
    <div class="spinner" v-if="!$store.getters.getLoaded">
        <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 6rem 0;"/>
    </div>
    <div id="properties" v-else>
        <div id="map">
            <GmapMap
                :center="marker"
                :zoom="mapZoom"
                map-type-id="terrain"
                :style="'width: 100%; height:' + mapHeight  + ';'"
                :options="mapStyle"
                >
                <GmapInfoWindow
                        :position="infoWindowPos"
                        :opened="infoWinOpen"
                        :options="infoOptions"
                        @closeclick="infoWinOpen=false">
                    <div class="displayMap">
                        <router-link :to="`/property/${currentMapDisplayProperty.category}s/${currentMapDisplayProperty.slug}`">
                            <img :src="currentMapDisplayProperty.imgURL" :alt="currentMapDisplayProperty.slug" />
                        </router-link>
                        <p>{{currentMapDisplayProperty.price}}</p>
                    </div>
                </GmapInfoWindow>
                <GmapMarker
                        v-for="(p, i) in propertiesWithMap"
                        :key="i + '_marker'"
                        :position="{lat: parseFloat(p.lat), lng: parseFloat(p.lng)}"
                        :clickable="true"
                        :icon="currentMidx === i ? selectedMarkerIcon : markerIcon"
                        @click="toggleInfoWindow(p, i)">
                </GmapMarker>
            </GmapMap>
            <div class="filters">
                <div class="openCloseFilters" 
                    v-on:click="openCloseFilter = !openCloseFilter"
                    :aria-label="`Click to ${!openCloseFilter ? 'expand' : 'collapse'} filter sections`">
                    {{openCloseFilterText}}
                    <font-awesome-icon class="icon" :icon="['fa', `${openCloseFilterIcon}`]" />
                </div>
                <transition 
                    name="showFiltersArea"
                    enter-active-class="animated faster fadeIn">
                <div v-if="openCloseFilter" class="filter_container">
                    <div class="filter">
                        <p class="filter_label">Listing</p>
                        <multiselect aria-label="Property Filter" id="0" @input="updatingFilters" :value="currentFilterOptions[0]" track-by="label" label="label" placeholder="Select listing" :showLabels="false" :options="listingFilter.options" :searchable="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">Status</p>
                        <multiselect aria-label="Status Filter" id="1" @input="updatingFilters" :value="currentFilterOptions[1]" track-by="label" label="label" placeholder="Select status" :showLabels="false" :options="statusFilter.options" :searchable="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">Price</p>
                        <multiselect aria-label="Price Filter" id="2" @input="updatingFilters" :value="currentFilterOptions[2]" track-by="label" label="label" placeholder="Select price" :options="priceFilter.options" :searchable="false" :showLabels="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">City</p>
                        <multiselect id="8" @input="updatingFilters" :value="currentFilterOptions[8]" :showLabels="false" :options="uniqueCities" :searchable="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">Beds</p>
                        <multiselect aria-label="Bedrooms Filter" id="3" @input="updatingFilters" :value="currentFilterOptions[3]" track-by="label" label="label" placeholder="Select beds" :options="bedsFilter.options" :searchable="false" :showLabels="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">Baths</p>
                        <multiselect aria-label="Bathrooms Filter" id="4" @input="updatingFilters" :value="currentFilterOptions[4]" track-by="label" label="label" placeholder="Select baths" :options="bathsFilter.options" :searchable="false" :showLabels="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">Type</p>
                        <multiselect aria-label="Type Filter" id="5" @input="updatingFilters" :value="currentFilterOptions[5]" track-by="label" label="label" placeholder="Select price" :options="typeFilter.options" :searchable="false" :showLabels="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">Pets</p>
                        <multiselect aria-label="Pets Filter" id="6" @input="updatingFilters" :value="currentFilterOptions[6]" track-by="label" label="label" placeholder="Select price" :options="petsFilter.options" :searchable="false" :showLabels="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                    <div class="filter">
                        <p class="filter_label">Laundry</p>
                        <multiselect aria-label="Laundry Filter" id="7" @input="updatingFilters" :value="currentFilterOptions[7]" track-by="label" label="label"  placeholder="Select laundry"  :options="laundryFilter.options" :searchable="false" :showLabels="false" :allow-empty="false" @select="infoWinOpen=false" :showNoResults="false">
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                        </multiselect>
                    </div>
                </div>
                </transition>
            </div>
        </div>
        <div class="search">
            <font-awesome-icon :icon="['fa', `search`]" class="icon"/>
            <input
                type="text"
                id="search"
                name="search"
                placeholder="e.g. 1417 Everett St."
                v-model="propertySearchText">
        </div>
        <div class="properties">
            <SingleProperty
                v-for="(listing, i) in filteredProperties" :key="i"
                :img="listing.FeaturedImage.data ? url+listing.FeaturedImage.data.attributes.url : '/images/placeholder.png'"
                :url="`/property/${listing.category}s/${listing.Slug}`"
                :status="listing.Status"
                :address="listing.Address"
                :beds="listing.Bedrooms"
                :baths="listing.Bathrooms"
                :price="listing.Price"
            />
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Spinner from 'vue-simple-spinner'
import SingleProperty from '../components/SingleProperty'
import { mapState, mapActions } from 'vuex';

export default {
    name: "Properties",
    components: {
        Spinner,
        Multiselect,
        SingleProperty
    },
    data(){
        return{
            url: 'https://strapi.cerda-zein.com',
            title: "Properties",
            window: {
                width: 0,
                height: 0
            },
            markerIcon :"/images/marker_black.png",
            selectedMarkerIcon :"/images/marker_orange.png",
            marker: {
                lat: 37.763999,
                lng: -122.240771,
            },
            mapStyle: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: [
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e9e9e9"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 29
                            },
                            {
                                "weight": 0.2
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 18
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dedede"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#333333"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            },
                            {
                                "lightness": 19
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    },
                    {
                        featureType: "poi",
                        stylers: [
                            { visibility: "off" }
                        ]
                    },
                    {
                        featureType: "transit",
                        stylers: [
                            { visibility: "off" }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [
                            {"visibility": "off"}
                        ]
                    }
                ]
            },
            openCloseFilter: false,
            listing: { label: "Any", value: "any" },
            listingFilter:{
                options:[
                    { label: "Any", value: "any" },
                    { label: "For Sale", value: "listing" },
                    { label: "Rental", value: 'rental' },
                ],
            },
            status: { label: "Available", value: "available" },
            statusFilter:{
                options:[
                    { label: "Any", value: "any" },
                    { label: "Available", value: 'available' },
                    { label: "Pending", value: "pending" },
                    { label: "Sold", value: 'sold' },
                    { label: "Rented", value: 'rented' },
                    { label: "Coming Soon", value: 'comingSoon' },
                ],
            },
            price: { label: "Any", price: [0, 1000000000, false] },
            priceFilter: {
                options:[
                    { label: "Any", price: [0, 1000000000, false] },
                    { label: '<$1,000', price: [0, 1000, false] },
                    { label: '$1,000 - 2,000', price: [1000, 2000, false] },
                    { label: '$2,000 - 3,000', price: [2000, 3000, false] },
                    { label: '$3,000 - 4,000', price: [3000, 4000, false] },
                    { label: '$4,000 - $10k', price: [4000, 10000, false] },
                    { label: '$10k - $50k', price: [10000, 50000, false] },
                    { label: '$50k - $500k', price: [50000, 500000, false] },
                    { label: '$500k - $1M', price: [500000, 1000000, false] },
                    { label: '$1M - $2M', price: [1000000, 2000000, false] },
                    { label: '$2M - $3M', price: [2000000, 3000000, false] },
                    { label: '$3M+', price: [3000000, 1000000000, false] },
                ],
            },
            beds: { label: "Any", value: "any" },
            bedsFilter:{
                options:[
                    { label: "Any", value: "any" },
                    { label: "N/A", value: 0 },
                    { label: "Studio", value: 0.5 },
                    { label: "1 Bedroom", value: 1 },
                    { label: "2 Bedrooms", value: 2 },
                    { label: "3 Bedrooms", value: 3 },
                    { label: "4+ Bedrooms", value: 4.5 },
                ],
            },
            baths: { label: "Any", value: "any" },
            bathsFilter:{
                options:[
                    { label: "Any", value: "any" },
                    { label: "N/A", value: 0 },
                    { label: "0.5 Bathroom", value: 0.5 },
                    { label: "1 Bathroom", value: 1 },
                    { label: "1.5 Bathrooms", value: 1.5 },
                    { label: "2 Bathrooms", value: 2 },
                    { label: "2.5 Bathrooms", value: 2.5 },
                    { label: "3 Bathrooms", value: 3 },
                    { label: "3.5 Bathrooms", value: 3.5 },
                    { label: "4 Bathrooms", value: 4 },
                ],
            },
            type: { label: "Any", value: "any" },
            typeFilter:{
                options:[
                    { label: "Any", value: "any" },
                    { label: "House / Cottage", value: "house" },
                    { label: "Townhouse", value: 'town' },
                    { label: "Condo / Apartment Unit", value: 'condo' },
                    { label: "Multiple Units", value: 'multi' },
                    { label: "Commercial", value: 'commercial' },
                ],
            },
            pets: { label: "Any", value: "any" },
            petsFilter:{
                options:[
                    { label: "Any", value: "any" },
                    { label: "N/A", value: 'na' },
                    { label: "Yes", value: "yes" },
                    { label: "No", value: 'no' },
                    { label: "Negotiable", value: "negotiable" },
                ],
            },
            laundry: { label: "Any", value: "any" },
            laundryFilter:{
                options:[
                    { label: "Any", value: "any" },
                    { label: "N/A", value: 'na' },
                    { label: "In Unit", value: "unit" },
                    { label: "Shared Laundry", value: "shared" },
                    { label: "None", value: 'none' },
                ],
            },
            city: 'Any',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            currentMapDisplayProperty:{
                category:"",
                imgURL:"",
                price:"",
                slug:""
            },
            infoOptions: {
                //optional: offset infowindow so it visually sits nicely on top of our marker
                maxWidth: 200,
                pixelOffset: {
                    width: 0,
                    height: -45
                }
            },
            loading: this.$store.getters.getLoaded,
            filter: "all",
            placeholderImage: "/images/placeholder.png",
            componentKey: 0
        }
    },
    computed: {
        propertiesWithMap: function () {
            return this.filteredProperties.filter(
                function (property) {
                    return property.lat !== '' && property.lng !== '';
                }
            )
        },
        uniqueCities: function(){
            let a = this.$store.getters.properties.map(p => p.City.trim()).filter((a, i, l) => l.indexOf(a) === i).sort();
            a.unshift("Any");
            return a;
        },
        filteredProperties:function(){
            let filteredProperties =  this.$store.getters.properties;
            if (this.componentKey < -1) {
                return filteredProperties;
            }
            if(this.currentFilterOptions[0].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.category === this.currentFilterOptions[0].value;
                })
            }
            if(this.currentFilterOptions[1].value !== 'any'){
                if(this.currentFilterOptions[1].value === 'available' || this.currentFilterOptions[1].value === 'comingSoon'){
                    filteredProperties = filteredProperties.filter((property) => {
                        return property.Status === 'available' || property.Status === 'comingSoon';
                    })
                } else {
                    filteredProperties = filteredProperties.filter((property) => {
                        return property.Status === this.currentFilterOptions[1].value;
                    })
                }
            }
            if(this.currentFilterOptions[3].value !== 'any'){
                if (this.beds.value !== 4.5){
                    filteredProperties = filteredProperties.filter((property) => {
                        return property.Bedrooms === this.currentFilterOptions[3].value;
                    })
                } else {
                    filteredProperties = filteredProperties.filter((property) => {
                        return property.Bedrooms >= 4;
                    })
                }
            }
            if(this.currentFilterOptions[4].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Bathrooms === this.currentFilterOptions[4].value;
                })
            }
            if(this.currentFilterOptions[5].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.BuildingType === this.currentFilterOptions[5].value;
                })
            }
            if(this.currentFilterOptions[6].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Pets === this.currentFilterOptions[6].value;
                })
            }
            if(this.currentFilterOptions[7].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Laundry === this.currentFilterOptions[7].value;
                })
            }
            if(this.currentFilterOptions[2].price.length !== 2){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Price <= this.currentFilterOptions[2].price[1] && property.Price >= this.currentFilterOptions[2].price[0]
                })
            }
            if(this.currentFilterOptions[8] !== 'Any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.City.trim().toLowerCase() === this.currentFilterOptions[8].trim().toLowerCase();
                })
            }
            if(this.propertySearchText !== ''){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Address.toLowerCase().includes(this.searchText.toLowerCase());
                })
            }

            return filteredProperties;
        },
        propertySearchText: {
           get(){
             return this.searchText
           },
           set(newText){
             this.updateSearchText(newText)
           } 
        },
        mapHeight:function(){
            return (window.innerHeight - 48) + 'px';
        },
        mapZoom:function(){
            if(this.window.width < 576){
                return 11;
            }
            return 12;
        },
        openCloseFilterText:function() {
            return this.openCloseFilter ? "Hide filters" : "Show filters";
        },
        openCloseFilterIcon:function() {
            return this.openCloseFilter ?  "caret-down" : "caret-up";
        },
        ...mapState(['currentFilterOptions', 'searchText'])
    },
    metaInfo: {
        title: 'Properties',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    methods:{
        toggleInfoWindow: function(property, idx) {
            this.infoWindowPos = {lat: parseFloat(property.lat), lng: parseFloat(property.lng)}
            this.currentMapDisplayProperty.imgURL = property.FeaturedImage ? this.url+property.FeaturedImage.data.attributes.url : this.placeholderImage;
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            let price = formatter.format(property.Price);
            this.currentMapDisplayProperty.price = price;
            this.currentMapDisplayProperty.slug = property.Slug;
            this.currentMapDisplayProperty.category = property.category;
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        clearFilters(){
            this.status = this.statusFilter.options[0];
            this.beds = this.bedsFilter.options[0];
            this.baths = this.bathsFilter.options[0];
            this.type = this.typeFilter.options[0];
            this.pets = this.petsFilter.options[0];
            this.laundry = this.laundryFilter.options[0];
            this.price = this.priceFilter.options[0];
            this.infoWinOpen = false;
        },
        updatingFilters(option, id){
            this.updateFilterOption(
                {
                    option:option, 
                    id: id
                }
            );
            if(Number(id) === 0){
                if(option.value === 'listing'){
                    this.$router.replace({ name:'properties', params: {category:'for-sale'} })
                } else if(option.value === 'rental'){
                    this.$router.replace({ name:'properties', params: {category:'rental' }})
                } else if(option.value === 'any'){
                    this.$router.replace({ name:'properties'})
                }
            }
            this.componentKey += 1;
        },
        ...mapActions(['updateFilterOption', 'updateSearchText'])
    },
    created() {
        if (this.$route.params.category){
            if (this.$route.params.category === 'rental') {
                this.currentFilterOptions[0] = this.listingFilter.options[2];
            } else if (this.$route.params.category === 'for-sale') {
                this.currentFilterOptions[0] = this.listingFilter.options[1];
            }
        }
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
        this.componentKey += 1;
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    watch: {
        $route() {
            if (this.$route.params.category){
                if (this.$route.params.category === 'rental') {
                    this.currentFilterOptions[0] = this.listingFilter.options[2];
                } else if (this.$route.params.category === 'for-sale') {
                    this.currentFilterOptions[0] = this.listingFilter.options[1];
                }
            }
            this.clearFilters();
            this.componentKey += 1;
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#map{
    position: relative;
    .filters{
        position: absolute;
        bottom: 0;
        width: 100%;
        .openCloseFilters{
            text-transform: uppercase;
            position: relative;
            text-align: center;
            left: 50%;
            margin-left: -70px;
            bottom: 0;
            color: #fff;
            background:coral;
            font-weight: 700;
            letter-spacing: 1.2px;
            padding: 2px 12px;
            font-size: 0.75rem;
            width: 140px;
            &:hover{
                cursor: pointer;
            }
            .icon{
                font-size: 1rem;
            }
        } 
        .filter_container{
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 32px 0;
            background: #fff;
            .filter{
                width: 33.3%;
                display: flex;
                justify-content: center;
                align-items: center;
                .filter_label{
                    width: 100px;
                    text-align: right;
                    margin-right: 10px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 1.2px;
                    font-size: 0.75rem;
                }
            }
        }
    }
}
.search{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    input{
        width: 200px;
        height: 1.8rem;
        margin-left: 10px;
        padding: 5px;
        font-size: 0.85rem;
        line-height: 1.5rem;
        font-family: $primaryFont;
        &:focus{
            outline: none;
        }
    }
}

.displayMap{
    text-align: center;
    img{
        width: 100%;
        height: auto;
    }
    p{
        margin: 3px auto;
        color: #000;
        letter-spacing: 1.2px;
        font-weight: 700;
    }
}
.properties{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: $mobile) {
    #map{
        .openCloseFilters{
            padding: 10px 12px;
        }
        .filters{
            .filter_container{
                .filter{
                    width: 100%;
                    .filter_label{
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
</style>
